import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {
      uid: "",
      mailAddress: "",
      imageToken: "",
    },
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.user = {
        uid: "",
        mailAddress: "",
        imageToken: "",
      };
    },
    updateImageToken: (state, action) => {
      state.user.imageToken = action.payload.imageToken;
    },
  },
});

export const { login, logout, updateImageToken } = userSlice.actions;
export const selectUser = (state: RootState) => state.user.user;
export const selectImageToken = (state: RootState) =>
  state.user.user.imageToken;
export default userSlice.reducer;
