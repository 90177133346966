import React, { useState, useEffect, useRef } from "react";
/** @jsxImportSource @emotion/react */
import { useSelector } from "react-redux";
import { css } from "@emotion/react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
  Paper,
  Button,
  InputLabel,
  FormControl,
  CircularProgress,
  Radio,
  IconButton,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { ThemeProvider } from "@mui/material/styles";
import Header from "../common/Header";
import TimeOutDisplay from "../common/TimeOutDisplay";
import {
  getWeekOptions,
  getMonthOptions,
  timer,
  fontTheme,
} from "../common/utils";
import APIErrorModal from "../common/APIErrorModal";
import PMASortModal from "../common/PMASortModal";
import ToggleButtonComponent from "../common/ToggleButtonComponent";
import { userAuthCheck } from "../auth/authUser";
import { getUserInfo } from "../../data/userInfo";
import { apiRequest } from "../../apis/util";
import CloseIcon from "@mui/icons-material/Close";
import { selectImageToken } from "../../features/userSlice";

const card = css`
  margin: 80px 0px 8px 0px;
  width: 100%;
  height: 100%;
  @media screen and (min-width: 1194px) {
    height: 620px;
  }
  @media screen and (min-width: 1366px) {
    height: 550px;
  }
`;
const table = css`
  table-layout: fixed;
  width: 100%;
  border: 0;
`;
const cardContent = css`
  padding: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 0;
`;
const tableContainer = css`
  border: 0;
  width: 99%;
  flex-grow: 1;
  overflow: auto;
  margin: 8px 8px 16px 8px;
  border: 0;
  @media screen and (min-width: 1194px) {
    max-height: 620px;
  }
  @media screen and (min-width: 1280px) {
    max-height: 460px;
  }
  @media screen and (min-width: 1366px) {
    max-height: 550px;
  }
`;
const commonCell = css`
  margin: 0;
  padding: 4px;
  white-space: pre-wrap;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #c4c4c4;
  font-size: 12px;
  box-sizing: border-box;
`;
const verticalHeaderCell = css`
  ${commonCell}
  width: 50px;
  height: 90px;
  background-color: #e0ffee;
  text-align: center;
  border-top: 2px solid #c4c4c4;
  border-bottom: 2px solid #c4c4c4;
  border-right: 2px solid #c4c4c4;
  border-left: 0;
  line-height: 1;
`;
const itemNameHeaderCell = css`
  ${verticalHeaderCell}
  width: 155px;
  z-index: 100;
`;
const infoItemNameHeaderCell = css`
  ${verticalHeaderCell}
  width: 130px;
  z-index: 50;
`;
const minVerticalHeaderCell = css`
  ${verticalHeaderCell}
  width: 40px;
`;
const besideHeaderCell = css`
  ${commonCell}
  padding: 0;
  width: 360px;
  height: 25px;
  background-color: #e0ffee;
  text-align: center;
  white-space: nowrap;
  box-sizing: border-box;
  border-top: 2px solid #c4c4c4;
  border-bottom: 2px solid #c4c4c4;
  border-right: 2px solid #c4c4c4;
  border-left: 0;
`;
const minBesideHeaderCell = css`
  ${besideHeaderCell}
  width: 70px;
`;
const joinHeaderCell = css`
  ${commonCell}
  background-color: #e0ffee;
  text-align: center;
  white-space: nowrap;
  box-sizing: border-box;
  width: 105px;
  height: 30px;
  border-top: 2px solid #c4c4c4;
  border-bottom: 2px solid #c4c4c4;
  border-right: 2px solid #c4c4c4;
  border-left: 0;
  z-index: 100;
`;
const colHeaderCell = css`
  ${commonCell}
  position: sticky;
  top: 36px;
  width: 30px;
  height: 90px;
  background-color: #e0ffee;
  border-top: 0;
  border-bottom: 2px solid #c4c4c4;
  border-right: 2px solid #c4c4c4;
  border-left: 0;
  line-height: 1;
  text-align: center;
`;
const secondHeader = css`
  ${commonCell}
  padding: 4px;
  position: sticky;
  width: 60px;
  background-color: #e0ffee;
  text-align: center;
  white-space: nowrap;
  box-sizing: border-box;
  top: 36px;
  border-top: 0;
  border-bottom: 2px solid #c4c4c4;
  border-right: 2px solid #c4c4c4;
  border-left: 0;
  line-height: 1;
  text-align: center;
`;
const sortedHeaderCell = css`
  ${colHeaderCell}
  background-color: #fff2cc;
`;
const numberCell = css`
  ${commonCell}
  position: sticky;
  padding: 2px;
  height: 45px;
  font-size: 12px;
  text-align: center;
  border-top: 0;
  border-bottom: 2px solid #c4c4c4;
  border-right: 2px solid #c4c4c4;
  border-left: 0;
  background-color: #ffffff;
`;
const sortedNumberCell = css`
  ${numberCell}
  background-color: #fff2cc;
`;
const nameCell = css`
  ${numberCell}
  padding: 4px;
  text-align: left;
  word-break: break-all;
`;
const circularProgress = css`
  position: absolute;
  top: 45%;
  left: 45%;
  opacity: 0.8;
  color: #ccc;
`;
const selectTerm = css`
  width: 200px;
  height: 35px;
  margin: 8px;
  padding: 0;
  text-align: left;
  background-color: #fff;
`;
const containerStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;
const rightContainerStyle = css`
  margin-left: auto;
`;
const operationSpace = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const operationLeft = css`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
`;
const operationRight = css`
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
`;
const linkText = css`
  text-decoration: none;
  color: blue;
  font-size: 12px;
`;
const designButton = css`
  border: 2px solid #00cc5b;
  background-color: white;
  color: black;
  border-radius: 20px;
  padding: 5px;
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;
const modalStyles = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 800px;
  height: 80%;
  max-height: 600px;
  border: 1px solid #000;
  background-color: white;
  overflow: auto;
`;
const radioStyle = (isSelected: any) => ({
  color: isSelected ? "#00cc5b" : "#ccc",
  "&.Mui-checked": {
    color: "#00cc5b",
  },
});

const AssortmentAnalysis: React.FC<{}> = ({}) => {
  const navigate = useNavigate();
  const [storePmaData, setStorePmaData] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [tenpoCode, setTenpoCode] = useState<string | null>("");
  const [tenpoName, setTenpoName] = useState<string>("");
  const [date, setDate] = useState("");
  const [order, setOrder] = useState<string>("asc");
  const [item, setItem] = useState("AllItems");
  const [orderBy, setOrderBy] = useState<string>("store_avg_quantity_rank");
  const [flagWM, setFlagWM] = useState("AssortmentAnalysisWeekly");
  const [weekDateOptions, setWeekDateOptions] = useState<string[]>([]);
  const [monthDateOptions, setMonthDateOptions] = useState<string[]>([]);
  const [weekDateValue, setWeekDateValue] = useState("");
  const [monthDateValue, setMonthDateValue] = useState("");
  const [selectedClusterNo, setSelectedClusterNo] = useState<string>("");
  const [apiData, setApiData] = useState<any>(null);
  const [storePmaAllList, setStorePmaAllList] = useState<any>(null);
  const [timeout, setTimeout] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorAPI, setErrorAPI] = useState(false);
  const [tobaccoFlag, setTobaccoFlag] = useState(false);
  const [reservationFlag, setReservationFlag] = useState(false);
  const [specialFlag, setSpecialFlag] = useState(false);
  const [sortModalOpen, setSortModalOpen] = useState(false);
  const [modalTobaccoFlag, setModalTobaccoFlag] = useState(false);
  const [modalReservationFlag, setModalReservationFlag] = useState(false);
  const [modalSpecialFlag, setModalSpecialFlag] = useState(false);
  const [modalValue, setModalValue] = useState("choice");
  const [isOpenDo, setIsOpenDo] = useState(true);
  const [isOpenCluster, setIsOpenCluster] = useState(true);
  const [Pdfopen, setPdfOpen] = useState(true);
  const [apiTrigger, setApiTrigger] = useState(0);
  const [clusterNos, setClusterNos] = useState<string[]>([]);

  const modalClose = () => setErrorAPI(false);

  const search = useLocation().search;
  const usp = new URLSearchParams(search);
  const windowName = "品揃え分析（上位100単品）";
  const ClusterNo = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "鉄道特殊1",
    "鉄道特殊2",
    "鉄道特殊3",
    "鉄道特殊4",
    "鉄道特殊5",
    "鉄道特殊6",
    "鉄道特殊7",
  ];

  const imageToken = useSelector(selectImageToken);
  const tempFilePath = "AffiliationGroup/manual/manual";
  const slideNum = 2;
  let imgFileList: string[] = [];
  for (let i = 1; i <= slideNum; i++) {
    imgFileList.push(
      process.env.REACT_APP_CLUSTER_MANUAL +
        tempFilePath +
        String(i) +
        ".webp" +
        "?access_token=" +
        imageToken
    );
  }

  useEffect(() => {
    let optionsW: any[] = getWeekOptions();
    let optionsM: any[] = getMonthOptions();
    setWeekDateOptions(optionsW);
    setMonthDateOptions(optionsM);
    setWeekDateValue(optionsW[0]);
    setMonthDateValue(optionsM[0]);

    const tc: string | null = usp.get("tenpocode");
    const userInfo = getUserInfo();
    if (process.env.REACT_APP_MODE !== "develop") {
      // 担当店と職種で画面表示を制御
      if (tc !== null && userAuthCheck(userInfo, tc)) {
        setTenpoCode(tc);
      } else {
        navigate("/tenpo-error");
      }
    } else {
      // ローカルでは制御しない
      if (tc !== null) {
        setTenpoCode(tc);
      }
    }
    let dt = new Date();
    const year = dt.getFullYear();
    const month = ("00" + (dt.getMonth() + 1)).slice(-2);
    setDate(year + month);
    window.setTimeout(dispMsg, timer);
  }, []);

  useEffect(() => {
    if (!timeout) {
      window.setTimeout(dispMsg, timer);
    }
  }, [timeout]);

  useEffect(() => {
    let res: any = {};
    let date: string = "";
    let newDate: string = "";
    if (flagWM === "AssortmentAnalysisWeekly") {
      if (!weekDateValue) {
        return;
      }
      date = weekDateValue.slice(0, -1);
    } else if (flagWM === "AssortmentAnalysisMonthly") {
      date = monthDateValue.concat("/01");
    }
    newDate = date.replace(/\//g, "-");
    const params = {
      mode: flagWM,
      tenpoCode: tenpoCode,
      date: newDate,
      clusterNo: selectedClusterNo,
      checkedInfo: selectedItems,
      tobaccoFlag: tobaccoFlag,
      reservationFlag: reservationFlag,
      specialFlag: specialFlag,
      dailyItemFlag: modalValue,
      selectedRank: orderBy,
    };
    (async () => {
      try {
        setLoading(true);
        res = await apiRequest(params);
        let tempData;
        let allData;
        if (flagWM === "AssortmentAnalysisWeekly") {
          tempData =
            res.data.data.AssortmentAnalysisWeeklySearchByParams[0]
              .assortmentAnalysis;
          allData =
            res.data.data.AssortmentAnalysisWeeklySearchByParams[0]
              .tenpoItemData;
        } else if (flagWM === "AssortmentAnalysisMonthly") {
          tempData =
            res.data.data.AssortmentAnalysisMonthlySearchByParams[0]
              .assortmentAnalysis;
          allData =
            res.data.data.AssortmentAnalysisMonthlySearchByParams[0]
              .tenpoItemData;
        }
        if (tempData[0].zo_code === "045") {
          setClusterNos(ClusterNo);
        } else {
          const filteredClusterNo: any = ClusterNo.filter(
            (cluster) => !cluster.includes("鉄道特殊")
          );
          setClusterNos(filteredClusterNo);
        }
        if (tempData && tempData.length === 0) {
          setErrorAPI(true);
        }
        if (tempData[0].tenpo_name !== null) {
          setTenpoName(tempData[0].tenpo_name);
        }
        setApiData(tempData);
        setSelectedClusterNo(tempData[0].cluster_no);
        // tenpoItemData を処理して combined_code を分割
        const processedTenpoItemData = allData.map((item: any) => {
          const [pma_cd, info_class_code] = item.combined_code.split("_");
          return {
            ...item, // 既存のプロパティを保持
            pma_cd,
            info_class_code,
          };
        });
        // 分割したデータをstorePmaAllListに格納
        const newStorePmaAllList = processedTenpoItemData.map((item: any) => ({
          pma_cd: item.combined_code.split("_")[0],
          pma_name: item.pma_name.replace(/\s/g, ""),
          info_cd: item.combined_code.split("_")[1],
          info_name: item.info_class_name.replace(/\s/g, ""),
          daily: item.daily,
          non_daily: item.non_daily,
        }));
        // storePmaAllListを更新
        setStorePmaAllList(newStorePmaAllList);
        const newStorePmaData = tempData.map((item: any) => ({
          pma_cd: item.pma_code,
          pma_name: item.pma_name.replace(/\s/g, ""),
          info_cd: item.info_class_code,
          info_name: item.info_class_name.replace(/\s/g, ""),
        }));
        newStorePmaData.sort((a: any, b: any) => {
          // pma_cd で比較
          const pmaComparison = a.pma_cd.localeCompare(b.pma_cd);
          // pma_cd が同じ場合は info_cd で比較
          if (pmaComparison === 0) {
            return a.info_cd.localeCompare(b.info_cd);
          }
          return pmaComparison;
        });
        const uniqueStorePmaData = newStorePmaData.reduce(
          (accumulator: any[], current: any) => {
            const isDuplicate = accumulator.some(
              (item) =>
                item.pma_cd === current.pma_cd &&
                item.info_cd === current.info_cd
            );
            if (!isDuplicate) {
              return [...accumulator, current];
            }
            return accumulator;
          },
          []
        );
        // storePmaData を更新
        setTobaccoFlag(item === "notobacco");
        setStorePmaData(uniqueStorePmaData);
      } catch (e) {
        setApiData([]);
        setErrorAPI(true);
      } finally {
        setLoading(false);
      }
    })();
  }, [
    weekDateValue,
    monthDateValue,
    item,
    flagWM,
    orderBy,
    modalValue,
    apiTrigger,
  ]);

  const dispMsg = () => {
    setTimeout(true);
  };

  const changeWeekMonth = (valueWM: string) => {
    setFlagWM(valueWM);
  };

  const handleDoToggle = () => {
    setIsOpenDo(!isOpenDo);
  };

  const handleClusterToggle = () => {
    setIsOpenCluster(!isOpenCluster);
  };

  const handleTermChange = (event: SelectChangeEvent) => {
    if (flagWM === "AssortmentAnalysisWeekly") {
      setWeekDateValue(event.target.value as string);
    } else if (flagWM === "AssortmentAnalysisMonthly") {
      setMonthDateValue(event.target.value as string);
    }
  };

  const handleOpenModal = () => {
    const formattedSelectedItems = storePmaData.map(
      (item: any) => `${item.pma_cd}_${item.info_cd}`
    );
    setModalTobaccoFlag(tobaccoFlag);
    setModalReservationFlag(reservationFlag);
    setModalSpecialFlag(specialFlag);
    setSelectedItems(formattedSelectedItems);
    setSortModalOpen(true);
  };

  const handleCloseModal = () => {
    setSortModalOpen(false);
  };

  const handlePdfClose = () => {
    setPdfOpen(false);
  };

  const handleConfirmSelection = (
    selected: string[],
    tobaccoFlag: boolean,
    reservationFlag: boolean,
    specialFlag: boolean,
    value: string
  ) => {
    setTobaccoFlag(tobaccoFlag);
    setReservationFlag(reservationFlag);
    setSpecialFlag(specialFlag);
    setModalValue(value);
    setSortModalOpen(false);
    // タバコフィルターの状態に合わせてitemを更新
    setItem(tobaccoFlag ? "notobacco" : "AllItems");
    let updatedSelectedItems = [...selected];
    // tobaccoFlag に基づいて selectedItems を更新
    if (tobaccoFlag) {
      updatedSelectedItems = updatedSelectedItems.filter(
        (item) => !item.startsWith("61_")
      );
    }
    setSelectedItems(updatedSelectedItems);
    setTobaccoFlag(tobaccoFlag);
    setItem(tobaccoFlag ? "notobacco" : "AllItems");

    setApiTrigger((prev) => prev + 1);
  };

  const handleRequestSort = (value: string) => {
    setOrder(value);

    // ソートロジックを適用するために、apiData が存在することを確認
    if (apiData && apiData.length > 0) {
      const sortedData = [...apiData].sort((a: any, b: any) => {
        // ソートキーを決定（orderByに基づいて）
        let sortKeyA: any;
        let sortKeyB: any;

        switch (orderBy) {
          case "store_avg_quantity_rank":
            sortKeyA = a.sales_quantity_rank;
            sortKeyB = b.sales_quantity_rank;
            break;
          case "cluster_avg_quantity_rank":
            sortKeyA = a.cluster_sales_quantity_rank;
            sortKeyB = b.cluster_sales_quantity_rank;
            break;
          case "do_avg_quantity_rank":
            sortKeyA = a.do_sales_quantity_rank;
            sortKeyB = b.do_sales_quantity_rank;
            break;
          case "store_avg_sales_rank":
            sortKeyA = a.sales_amount_rank;
            sortKeyB = b.sales_amount_rank;
            break;
          case "cluster_avg_sales_rank":
            sortKeyA = a.cluster_sales_amount_rank;
            sortKeyB = b.cluster_sales_amount_rank;
            break;
          case "do_avg_sales_rank":
            sortKeyA = a.do_sales_amount_rank;
            sortKeyB = b.do_sales_amount_rank;
            break;
        }

        // 数値として比較
        if (typeof sortKeyA === "string" && !isNaN(Number(sortKeyA))) {
          sortKeyA = Number(sortKeyA);
        }
        if (typeof sortKeyB === "string" && !isNaN(Number(sortKeyB))) {
          sortKeyB = Number(sortKeyB);
        }

        // 昇順または降順で比較
        if (value === "asc") {
          return sortKeyA - sortKeyB;
        } else {
          return sortKeyB - sortKeyA;
        }
      });

      // ソートされたデータを更新
      setApiData(sortedData);
    }
  };

  const handleChange = (value: string) => {
    setItem(value);
    setTobaccoFlag(value === "notobacco");

    if (value === "notobacco" && storePmaAllList) {
      const tobaccoItems = storePmaAllList
        .filter((pma: any) => pma.pma_cd === "61")
        .map((pma: any) => `${pma.pma_cd}_${pma.info_cd}`);
      const updatedSelectedItems = selectedItems.filter(
        (item) => !tobaccoItems.includes(item)
      );
      setSelectedItems(updatedSelectedItems);
    } else if (value === "AllItems" && storePmaAllList) {
      const tobaccoItems = storePmaAllList
        .filter((pma: any) => pma.pma_cd === "61")
        .map((pma: any) => `${pma.pma_cd}_${pma.info_cd}`);

      setSelectedItems([...selectedItems, ...tobaccoItems]);
    }
    setApiTrigger((prev) => prev + 1);
  };

  const handleClusterNoChange = (event: SelectChangeEvent<string>) => {
    setSelectedClusterNo(event.target.value);
    if (event.target.value !== null) {
      setApiTrigger((prev) => prev + 1);
    }
  };

  const getAffiliationUrl = () => {
    let url = `/affiliation-group?tenpocode=${tenpoCode}`;
    if (selectedClusterNo !== null) {
      url += `&cluster=${selectedClusterNo}`;
    }
    return url;
  };

  const handleSortChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrderBy(event.target.value);
  };

  const insertLineBreaks = (str: string) => {
    return str.split("").join("\n");
  };

  const insertLineBreaksItemName = (str: string, byteLength: number = 33) => {
    // デフォルト値は22バイト（全角11文字相当）
    if (!str) return "";
    let result = "";
    let currentLine = "";
    const encoder = new TextEncoder();

    for (const char of str) {
      currentLine += char;
      const byteCount = encoder.encode(currentLine).length;
      if (byteCount > byteLength) {
        result += currentLine.slice(0, -1) + "\n"; // バイトオーバーする直前の文字までで改行
        currentLine = char; // オーバーした文字を次の行の先頭に
      }
    }
    result += currentLine; // 最後の行を追加
    return result;
  };

  const EnhancedTableHead: React.FC<{}> = ({}) => {
    return (
      <TableHead>
        <TableRow>
          <TableCell
            css={joinHeaderCell}
            style={{ left: "0px", borderLeft: "2px solid #c4c4c4" }}
            colSpan={3}
          >
            数量順位
          </TableCell>
          <TableCell css={joinHeaderCell} style={{ left: "105px" }} colSpan={3}>
            金額順位
          </TableCell>
          <TableCell
            css={itemNameHeaderCell}
            style={{ left: "210px" }}
            rowSpan={2}
          >
            {insertLineBreaks("商品名")}
          </TableCell>
          <TableCell css={infoItemNameHeaderCell} rowSpan={2}>
            {insertLineBreaks("情報分類")}
          </TableCell>
          <TableCell css={verticalHeaderCell} rowSpan={2}>
            {insertLineBreaks("商品コード")}
          </TableCell>
          <TableCell css={minVerticalHeaderCell} rowSpan={2}>
            {insertLineBreaks("商品単価")}
          </TableCell>
          <TableCell css={minVerticalHeaderCell} rowSpan={2}>
            {insertLineBreaks("基本商品")}
          </TableCell>
          <TableCell css={minBesideHeaderCell} colSpan={2}>
            導入率
          </TableCell>
          <TableCell css={besideHeaderCell} colSpan={6}>
            自店
          </TableCell>
          {isOpenCluster && (
            <TableCell css={besideHeaderCell} colSpan={6}>
              類似店
            </TableCell>
          )}
          {isOpenDo && (
            <TableCell css={besideHeaderCell} colSpan={6}>
              DO
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell
            css={
              orderBy === "store_avg_quantity_rank"
                ? sortedHeaderCell
                : colHeaderCell
            }
            style={{
              borderLeft: "2px solid #c4c4c4",
              left: "0px",
              zIndex: 100,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              自<br />店
              <div>
                <Radio
                  value="store_avg_quantity_rank"
                  checked={orderBy === "store_avg_quantity_rank"}
                  onChange={handleSortChange}
                  sx={radioStyle(orderBy === "store_avg_quantity_rank")}
                />
              </div>
            </div>
          </TableCell>
          <TableCell
            css={
              orderBy === "cluster_avg_quantity_rank"
                ? sortedHeaderCell
                : colHeaderCell
            }
            style={{ left: "35px", zIndex: 100 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              類<br />似<br />店
              <div>
                <Radio
                  value="cluster_avg_quantity_rank"
                  checked={orderBy === "cluster_avg_quantity_rank"}
                  onChange={handleSortChange}
                  sx={radioStyle(orderBy === "cluster_avg_quantity_rank")}
                />
              </div>
            </div>
          </TableCell>
          <TableCell
            css={
              orderBy === "do_avg_quantity_rank"
                ? sortedHeaderCell
                : colHeaderCell
            }
            style={{ left: "70px", zIndex: 100 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              地<br />区
              <div>
                <Radio
                  value="do_avg_quantity_rank"
                  checked={orderBy === "do_avg_quantity_rank"}
                  onChange={handleSortChange}
                  sx={radioStyle(orderBy === "do_avg_quantity_rank")}
                />
              </div>
            </div>
          </TableCell>
          <TableCell
            css={
              orderBy === "store_avg_sales_rank"
                ? sortedHeaderCell
                : colHeaderCell
            }
            style={{ left: "105px", zIndex: 100 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              自<br />店
              <div>
                <Radio
                  value="store_avg_sales_rank"
                  checked={orderBy === "store_avg_sales_rank"}
                  onChange={handleSortChange}
                  sx={radioStyle(orderBy === "store_avg_sales_rank")}
                />
              </div>
            </div>
          </TableCell>
          <TableCell
            css={
              orderBy === "cluster_avg_sales_rank"
                ? sortedHeaderCell
                : colHeaderCell
            }
            style={{ left: "140px", zIndex: 100 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              類<br />似<br />店
              <div>
                <Radio
                  value="cluster_avg_sales_rank"
                  checked={orderBy === "cluster_avg_sales_rank"}
                  onChange={handleSortChange}
                  sx={radioStyle(orderBy === "cluster_avg_sales_rank")}
                />
              </div>
            </div>
          </TableCell>
          <TableCell
            css={
              orderBy === "do_avg_sales_rank" ? sortedHeaderCell : colHeaderCell
            }
            style={{ left: "175px", zIndex: 100 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              地<br />区
              <div>
                <Radio
                  value="do_avg_sales_rank"
                  checked={orderBy === "do_avg_sales_rank"}
                  onChange={handleSortChange}
                  sx={radioStyle(orderBy === "do_avg_sales_rank")}
                />
              </div>
            </div>
          </TableCell>
          <TableCell css={colHeaderCell}>
            {insertLineBreaks("類似店")}
          </TableCell>
          <TableCell css={colHeaderCell}>{insertLineBreaks("DO")}</TableCell>
          <TableCell css={secondHeader}>
            販売
            <br />
            数/日
            <br />
            （千円）
          </TableCell>
          <TableCell css={secondHeader}>販売金額</TableCell>
          <TableCell css={secondHeader}>
            欠品率
            <br />
            (デイ
            <br />
            リー)
          </TableCell>
          <TableCell css={secondHeader}>
            欠品時
            <br />
            間(日)
          </TableCell>
          <TableCell css={secondHeader}>
            廃棄率
            <br />
            (数量)
          </TableCell>
          <TableCell css={secondHeader}>
            廃棄率
            <br />
            (金額)
          </TableCell>
          {isOpenCluster && (
            <>
              <TableCell css={secondHeader}>
                販売
                <br />
                数/日
                <br />
                （千円）
              </TableCell>
              <TableCell css={secondHeader}>販売金額</TableCell>
              <TableCell css={secondHeader}>
                欠品率
                <br />
                (デイ
                <br />
                リー)
              </TableCell>
              <TableCell css={secondHeader}>
                欠品時
                <br />
                間(日)
              </TableCell>
              <TableCell css={secondHeader}>
                廃棄率
                <br />
                (数量)
              </TableCell>
              <TableCell css={secondHeader}>
                廃棄率
                <br />
                (金額)
              </TableCell>
            </>
          )}
          {isOpenDo && (
            <>
              <TableCell css={secondHeader}>
                販売
                <br />
                数/日
                <br />
                （千円）
              </TableCell>
              <TableCell css={secondHeader}>販売金額</TableCell>
              <TableCell css={secondHeader}>
                欠品率
                <br />
                (デイ
                <br />
                リー)
              </TableCell>
              <TableCell css={secondHeader}>
                欠品時
                <br />
                間(日)
              </TableCell>
              <TableCell css={secondHeader}>
                廃棄率
                <br />
                (数量)
              </TableCell>
              <TableCell css={secondHeader}>
                廃棄率
                <br />
                (金額)
              </TableCell>
            </>
          )}
        </TableRow>
      </TableHead>
    );
  };

  return timeout ? (
    <TimeOutDisplay setTimeout={setTimeout} />
  ) : (
    <>
      <ThemeProvider theme={fontTheme()}>
        <Header
          windowName={windowName}
          tenpoCode={tenpoCode ? tenpoCode : ""}
          tenpoName={tenpoName ? tenpoName : ""}
        />
        <Grid container>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={11}>
            <Card css={card} variant="outlined">
              <Modal open={errorAPI} onClose={modalClose}>
                <APIErrorModal />
              </Modal>
              <Modal open={Pdfopen} onClose={handlePdfClose}>
                <div css={modalStyles}>
                  <div>
                    <IconButton
                      onClick={() => {
                        setPdfOpen(false);
                      }}
                      size={"small"}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <h3 id="simple-modal-title"></h3>
                  {imgFileList
                    ? imgFileList.map((res: any, index: number) => (
                        <div key={index}>
                          <img alt="" src={res} width="100%" height="100%" />
                        </div>
                      ))
                    : ""}
                </div>
              </Modal>
              <CardContent css={cardContent}>
                {loading ? (
                  <div>
                    <CircularProgress css={circularProgress} size={120} />
                  </div>
                ) : (
                  <>
                    <div css={containerStyle}>
                      <Button onClick={handleOpenModal} css={designButton}>
                        分類から選択
                      </Button>
                      <Button css={designButton}>
                        <a
                          href={getAffiliationUrl()}
                          target="_blank"
                          rel="noopener noreferrer"
                          css={linkText}
                        >
                          所属グループ特徴へ
                        </a>
                      </Button>
                      <div css={rightContainerStyle}>
                        <FormControl disabled>
                          <InputLabel id="cluster-select-label">
                            類似店番号
                          </InputLabel>
                          <Select
                            labelId="cluster-select-label"
                            id="cluster-select"
                            value={selectedClusterNo}
                            onChange={handleClusterNoChange}
                            css={selectTerm}
                            label="類似店番号"
                            disabled={loading}
                          >
                            {clusterNos.map((option: any) => {
                              const isOrgCluster =
                                apiData &&
                                apiData.length > 0 &&
                                apiData[0].org_cluster_no === option;
                              const isSelectedCluster =
                                selectedClusterNo === option;
                              let border = "1px solid inherit"; // デフォルトの枠線
                              let borderColor = "inherit"; // デフォルトの色
                              let backgroundColor = "transparent";
                              if (isSelectedCluster) {
                                backgroundColor = "#fff2cc"; // 選択中のクラスターの色
                              }
                              if (isOrgCluster) {
                                borderColor = "#00cc5b"; // 所属クラスターの色
                              }
                              if (isOrgCluster || isSelectedCluster) {
                                border = `2px solid ${borderColor}`;
                              }
                              return (
                                <MenuItem
                                  key={option}
                                  value={option}
                                  style={{
                                    height: "20%",
                                    border: border,
                                    borderColor: borderColor,
                                    backgroundColor: backgroundColor,
                                  }}
                                >
                                  {option}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        {flagWM === "AssortmentAnalysisWeekly" ? (
                          <Select
                            labelId="select-label"
                            id="simple-select"
                            value={weekDateValue}
                            label="select week"
                            onChange={handleTermChange}
                            css={selectTerm}
                            disabled={loading}
                          >
                            {weekDateOptions.map((option: any) => (
                              <MenuItem
                                key={option}
                                value={option}
                                style={{ height: "20%" }}
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <Select
                            labelId="select-label"
                            id="simple-select"
                            value={monthDateValue}
                            label="select week"
                            onChange={handleTermChange}
                            css={selectTerm}
                            disabled={loading}
                          >
                            {monthDateOptions.map((option: any) => (
                              <MenuItem
                                key={option}
                                value={option}
                                style={{ height: "20%" }}
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </div>
                      <Modal open={sortModalOpen} onClose={handleCloseModal}>
                        <PMASortModal
                          onConfirm={handleConfirmSelection}
                          storePmaAllList={storePmaAllList}
                          storePmaData={storePmaData}
                          selectedItems={selectedItems}
                          tobaccoFlag={modalTobaccoFlag} // 親コンポーネントのtobaccoFlagを渡す
                          onTobaccoFlagChange={setModalTobaccoFlag}
                          reservationFlag={modalReservationFlag}
                          specialFlag={modalSpecialFlag}
                          value={modalValue}
                          setTobaccoFlag={setModalTobaccoFlag}
                          setReservationFlag={setModalReservationFlag}
                          setSpecialFlag={setModalSpecialFlag}
                          setValue={setModalValue}
                        />
                      </Modal>
                    </div>
                    <div css={operationSpace}>
                      <div css={operationLeft}>
                        <ToggleButtonComponent
                          options={[
                            {
                              value: "AssortmentAnalysisWeekly",
                              label: "週別",
                            },
                            {
                              value: "AssortmentAnalysisMonthly",
                              label: "月別",
                            },
                          ]}
                          selectedValue={flagWM}
                          orderBy={"none"}
                          onChange={changeWeekMonth}
                          loadFlag={loading}
                        />
                        <ToggleButtonComponent
                          options={[
                            { value: "AllItems", label: "煙草含む" },
                            { value: "notobacco", label: "煙草除く" },
                          ]}
                          selectedValue={item}
                          orderBy={"none"}
                          onChange={handleChange}
                          loadFlag={loading}
                        />
                        <ToggleButtonComponent
                          options={[
                            {
                              value: "asc",
                              label: "昇順",
                            },
                            {
                              value: "desc",
                              label: "降順",
                            },
                          ]}
                          selectedValue={order}
                          orderBy={"none"}
                          onChange={handleRequestSort}
                          loadFlag={loading}
                        />
                      </div>
                      <div css={operationRight}>
                        <ToggleButtonComponent
                          options={[
                            { value: true, label: "類似店表示" },
                            { value: false, label: "類似店非表示" },
                          ]}
                          selectedValue={isOpenCluster}
                          orderBy={"none"}
                          onChange={handleClusterToggle}
                          loadFlag={loading}
                        />
                        <ToggleButtonComponent
                          options={[
                            { value: true, label: "DO表示" },
                            { value: false, label: "DO非表示" },
                          ]}
                          selectedValue={isOpenDo}
                          orderBy={"none"}
                          onChange={handleDoToggle}
                          loadFlag={loading}
                        />
                      </div>
                    </div>
                    <TableContainer component={Paper} css={tableContainer}>
                      <Table stickyHeader css={table}>
                        <EnhancedTableHead />
                        <TableHead></TableHead>
                        <TableBody>
                          {apiData.map((data: any, index: number) => (
                            <TableRow key={index}>
                              <TableCell
                                css={
                                  orderBy === "store_avg_quantity_rank"
                                    ? sortedNumberCell
                                    : numberCell
                                }
                                style={{
                                  left: "0px",
                                  zIndex: 50,
                                  borderLeft: "2px solid #c4c4c4",
                                }}
                              >
                                {data?.sales_quantity_rank}
                              </TableCell>
                              <TableCell
                                css={
                                  orderBy === "cluster_avg_quantity_rank"
                                    ? sortedNumberCell
                                    : numberCell
                                }
                                style={{ left: "35px", zIndex: 50 }}
                              >
                                {data?.cluster_sales_quantity_rank}
                              </TableCell>
                              <TableCell
                                css={
                                  orderBy === "do_avg_quantity_rank"
                                    ? sortedNumberCell
                                    : numberCell
                                }
                                style={{ left: "70px", zIndex: 50 }}
                              >
                                {data?.do_sales_quantity_rank}
                              </TableCell>
                              <TableCell
                                css={
                                  orderBy === "store_avg_sales_rank"
                                    ? sortedNumberCell
                                    : numberCell
                                }
                                style={{ left: "105px", zIndex: 50 }}
                              >
                                {data?.sales_amount_rank}
                              </TableCell>
                              <TableCell
                                css={
                                  orderBy === "cluster_avg_sales_rank"
                                    ? sortedNumberCell
                                    : numberCell
                                }
                                style={{ left: "140px", zIndex: 50 }}
                              >
                                {data?.cluster_sales_amount_rank}
                              </TableCell>
                              <TableCell
                                css={
                                  orderBy === "do_avg_sales_rank"
                                    ? sortedNumberCell
                                    : numberCell
                                }
                                style={{ left: "175px", zIndex: 50 }}
                              >
                                {data?.do_sales_amount_rank}
                              </TableCell>
                              <TableCell
                                css={nameCell}
                                style={{
                                  left: "210px",
                                  zIndex: 50,
                                  width: "155px",
                                }}
                              >
                                {insertLineBreaksItemName(data?.item_name)}
                              </TableCell>
                              <TableCell css={nameCell}>
                                {data?.info_class_name}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.item_code}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.item_price}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.basic_item}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.adoption_rate_similar_stores}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.adoption_rate_do}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.average_sales_quantity}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.average_sales_amount}
                              </TableCell>
                              <TableCell
                                css={[
                                  numberCell,
                                  data.average_out_of_stock_rate >
                                    data.cluster_average_out_of_stock_rate &&
                                    css`
                                      color: red;
                                    `,
                                ]}
                              >
                                {data?.average_out_of_stock_rate}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.average_out_of_stock_time}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.average_disposal_quantity_rate}
                              </TableCell>
                              <TableCell css={numberCell}>
                                {data?.average_disposal_amount_rate}
                              </TableCell>
                              {isOpenCluster && (
                                <>
                                  <TableCell css={numberCell}>
                                    {data?.cluster_average_sales_quantity}
                                  </TableCell>
                                  <TableCell css={numberCell}>
                                    {data?.cluster_average_sales_amount}
                                  </TableCell>
                                  <TableCell css={numberCell}>
                                    {data?.cluster_average_out_of_stock_rate}
                                  </TableCell>
                                  <TableCell css={numberCell}>
                                    {data?.cluster_average_out_of_stock_time}
                                  </TableCell>
                                  <TableCell css={numberCell}>
                                    {
                                      data?.cluster_average_disposal_quantity_rate
                                    }
                                  </TableCell>
                                  <TableCell css={numberCell}>
                                    {data?.cluster_average_disposal_amount_rate}
                                  </TableCell>
                                </>
                              )}
                              {isOpenDo && (
                                <>
                                  <TableCell css={numberCell}>
                                    {data?.do_average_sales_quantity}
                                  </TableCell>
                                  <TableCell css={numberCell}>
                                    {data?.do_average_sales_amount}
                                  </TableCell>
                                  <TableCell css={numberCell}>
                                    {data?.do_average_out_of_stock_rate}
                                  </TableCell>
                                  <TableCell css={numberCell}>
                                    {data?.do_average_out_of_stock_time}
                                  </TableCell>
                                  <TableCell css={numberCell}>
                                    {data?.do_average_disposal_quantity_rate}
                                  </TableCell>
                                  <TableCell css={numberCell}>
                                    {data?.do_average_disposal_amount_rate}
                                  </TableCell>
                                </>
                              )}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={0.5}></Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
};
export default AssortmentAnalysis;
