import React from "react";
// import ReactDOM from 'react-dom/client'
import { createRoot } from "react-dom/client";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import Auth from "./components/auth/Auth";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { tokenLogin } from "./components/auth/Login";
import DailyStoreSummaryTable from "./components/daily_store_single/summary/DailyStoreSummaryTable";
import DailyStoreSalesTable from "./components/daily_store_single/sales/DailyStoreSalesTable";
import DailyManagement from "./components/daily_management/DailyManagement";
import DisposalVerificationPma from "./components/disposal_verification/DisposalVerificationPma";
// import DisposalVerificationInfo from "./components/disposal_verification/DisposalVerificationInfo";
import WasteVerification from "./components/waste_verification/WasteVerification";
import MonthlyManagement from "./components/monthly_management/MonthlyManagement";
import DailyStoreCustomerCountTable from "./components/daily_store_single/customer_count/DailyStoreCustomerCountTable";
import DailyStoreUnitPriceTable from "./components/daily_store_single/unit_price/DailyStoreUnitPriceTable";
import DailyStoreQuantityTable from "./components/daily_store_single/quantity/DailyStoreQuantityTable";
import DailyStoreMultiTable from "./components/daily_store_multi/DailyStoreMultiTable";
import ErrorDisplay from "./components/common/ErrorDisplay";
import PosPma from "./components/pos_pma/PosPma";
import PosPmaSummary from "./components/pos_pma/PosPmaSummary";
import PosTransitionPma from "./components/pos_pma/PosTransitionPma";
import InfoClass from "./components/info_class/InfoClass";
import AssortmentAnalysis from "./components/clustering/AssortmentAnalysis";
import AffiliationGroup from "./components/clustering/AffiliationGroup";
// import * as serviceWorker from "./serviceWorker";
import ProtectedRoute from "./ProtectedRoute";

tokenLogin(window.location.href);

const rootElement = document.getElementById("root");
if (rootElement) {
  const root = createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/login" element={<Auth />} />
            <Route path="/tenpo-error" element={<ErrorDisplay />} />
            {/* 日別管理表 */}
            <Route path="/daily-management" element={<ProtectedRoute />}>
              <Route path="/daily-management" element={<DailyManagement />} />
            </Route>
            {/* 月別管理表 */}
            <Route path="/monthly-management" element={<ProtectedRoute />}>
              <Route
                path="/monthly-management"
                element={<MonthlyManagement />}
              />
            </Route>
            {/* 廃棄検証（PMA） */}
            <Route
              path="/disposal-verification-pma"
              element={<ProtectedRoute />}
            >
              <Route
                path="/disposal-verification-pma"
                element={<DisposalVerificationPma />}
              />
            </Route>
            {/* 廃棄検証（情報分類） */}
            {/* <Route path="/disposal-verification-info" element={<ProtectedRoute />}>
            <Route path="/disposal-verification-info" element={<DisposalVerificationInfo />} />
          </Route> */}
            {/* 個店経営状況（個別）サマリ */}
            <Route path="/daily-store-summary" element={<ProtectedRoute />}>
              <Route
                path="/daily-store-summary"
                element={<DailyStoreSummaryTable />}
              />
            </Route>
            {/* 個店経営状況（個別）売上 */}
            <Route path="/daily-store-sales" element={<ProtectedRoute />}>
              <Route
                path="/daily-store-sales"
                element={<DailyStoreSalesTable />}
              />
            </Route>
            {/* 個店経営状況（個別）客数 */}
            <Route
              path="/daily-store-customer-count"
              element={<ProtectedRoute />}
            >
              <Route
                path="/daily-store-customer-count"
                element={<DailyStoreCustomerCountTable />}
              />
            </Route>
            {/* 個店経営状況（個別）客単価 */}
            <Route path="/daily-store-unit-price" element={<ProtectedRoute />}>
              <Route
                path="/daily-store-unit-price"
                element={<DailyStoreUnitPriceTable />}
              />
            </Route>
            {/* 個店経営状況（個別）買上点数 */}
            <Route path="/daily-store-quantity" element={<ProtectedRoute />}>
              <Route
                path="/daily-store-quantity"
                element={<DailyStoreQuantityTable />}
              />
            </Route>
            {/* 個店経営状況（個別）サブ */}
            <Route path="/daily-store-multi" element={<ProtectedRoute />}>
              <Route
                path="/daily-store-multi"
                element={<DailyStoreMultiTable />}
              />
            </Route>
            <Route path="/waste-verification" element={<WasteVerification />} />
            {/* POS-PMA分析 */}
            <Route path="/pos-analysis-summary" element={<ProtectedRoute />}>
              <Route path="/pos-analysis-summary" element={<PosPmaSummary />} />
            </Route>
            {/* POS-PMA */}
            <Route path="/pos-analysis-pma" element={<ProtectedRoute />}>
              <Route path="/pos-analysis-pma" element={<PosPma />} />
            </Route>
            {/* 情報分類 */}
            <Route path="/info-class" element={<ProtectedRoute />}>
              <Route path="/info-class" element={<InfoClass />} />
            </Route>
            {/* POSPMA推移 */}
            <Route path="/pos-analysis-transition" element={<ProtectedRoute />}>
              <Route
                path="/pos-analysis-transition"
                element={<PosTransitionPma />}
              />
            </Route>
            {/*品揃え分析（上位100単品）*/}
            <Route path="/assortment-analysis" element={<ProtectedRoute />}>
              <Route
                path="/assortment-analysis"
                element={<AssortmentAnalysis />}
              />
            </Route>
            {/* 所属グループ特徴 */}
            <Route path="/affiliation-group" element={<ProtectedRoute />}>
              <Route path="/affiliation-group" element={<AffiliationGroup />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  );
}
