import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
/** @jsxImportSource @emotion/react */
import { IconButton, Button, Modal } from "@mui/material";
import { css } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import { selectImageToken } from "../../features/userSlice";

const designButton = css`
  border: 2px solid #00cc5b;
  background-color: white;
  color: black;
  border-radius: 20px;
  padding: 5px;
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const modalStyles = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 800px;
  height: 80%;
  max-height: 600px;
  border: 1px solid #000;
  background-color: white;
  overflow: auto;
`;

const ManualButton: React.FC<{}> = () => {
  const [open, setOpen] = useState(false);
  const imageToken = useSelector(selectImageToken);
  const tempFilePath = "AffiliationGroup/manual/manual";
  const slideNum = 2;
  let imgFileList: string[] = [];
  for (let i = 1; i <= slideNum; i++) {
    imgFileList.push(
      process.env.REACT_APP_CLUSTER_MANUAL +
        tempFilePath +
        String(i) +
        ".webp" +
        "?access_token=" +
        imageToken
    );
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleOpen} css={designButton}>
        PDFを開く
      </Button>
      <Modal open={open} onClose={handleClose}>
        <div css={modalStyles}>
          <div>
            <IconButton
              onClick={() => {
                setOpen(false);
              }}
              size={"small"}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <h3 id="simple-modal-title"></h3>
          {imgFileList
            ? imgFileList.map((res: any, index: number) => (
                <div key={index}>
                  <img alt="" src={res} width="100%" height="100%" />
                </div>
              ))
            : ""}
        </div>
      </Modal>
    </>
  );
};

export default ManualButton;
