import { createTheme } from "@mui/material/styles";

// 画面表示時間
export const timer = 1800000;

// APIから取得した値を表の表示用に変換する
export const dataChange = (
  data: number,
  isDouble: boolean = false,
  digit: number = 1
) => {
  let dataChange = "";
  if (data) {
    switch (data) {
      case -111111111:
        dataChange = "";
        break;
      case -888888888:
        dataChange = "-";
        break;
      case -999999999:
        dataChange = "######.#";
        break;
      default:
        dataChange = isDouble
          ? data.toLocaleString(undefined, {
              minimumFractionDigits: digit,
              maximumFractionDigits: digit,
            })
          : data.toLocaleString();
        break;
    }
  }　else if(data === 0) {
    dataChange = data.toLocaleString();
  }
  return dataChange;
};

// 小数点以下の桁数を調整する
export const addDecimalPoint = (value: number, digit: number = 1) => {
  if (value || value === 0) {
    return value.toLocaleString(undefined, {
      minimumFractionDigits: digit,
      maximumFractionDigits: digit,
    });
  } else {
    return "";
  }
};

// 3桁区切りのカンマを加える
export const addComma = (value: number) => {
  if (value || value === 0) {
    return value.toLocaleString();
  } else {
    return "";
  }
};

// 土日祝日を色分けする
export const getHolidayStyle = (data: any) => {
  let dateColor: string = "black";
  if (data.day_of_week === 1 || data.is_holiday === true) {
    dateColor = "red";
  } else if (data.day_of_week === 7) {
    dateColor = "blue";
  }
  return dateColor;
};

// 前年の土日祝日を色分けする
export const getLastYearHolidayStyle = (data: any) => {
  let dateColor: string = "black";
  if (data.day_of_week_last_year === 1 || data.is_holiday_last_year === true) {
    dateColor = "red";
  } else if (data.day_of_week_last_year === 7) {
    dateColor = "blue";
  }
  return dateColor;
};

// yyyy-MM-ddをMM/ddに変換
export const formatMMDD = (date: string) => {
  const parts = date.split("-");
  return parts[1] + "/" + parts[2];
};

export const getDayOfWeek = (day: number) => {
  let dayOfWeek = "";
  switch (day) {
    case 1:
      dayOfWeek = "(日)";
      break;
    case 2:
      dayOfWeek = "(月)";
      break;
    case 3:
      dayOfWeek = "(火)";
      break;
    case 4:
      dayOfWeek = "(水)";
      break;
    case 5:
      dayOfWeek = "(木)";
      break;
    case 6:
      dayOfWeek = "(金)";
      break;
    case 7:
      dayOfWeek = "(土)";
      break;
  }
  return dayOfWeek;
};

export const graphColors: {
  A: string;
  B: string;
  C: string;
  D: string;
} = {
  A: "#ff9872",
  B: "#86f9c3",
  C: "#c299ff",
  D: "#8ef1ff",
};

// 日付の古い⇒新しい順にソートして日付をMM/ddに変換
export const arrangeData = (data: any) => {
  const sortedData = data.sort(function (a: any, b: any) {
    return a.date < b.date ? -1 : 1;
  });
  sortedData.map((data: any) => (data.date = formatMMDD(data.date)));
  return sortedData;
};

// 過去１年間の日付を週ごとに成形
export const getWeekOptions = (): string[] => {
  const options: string[] = [];
  const now = new Date();
  const currentDay = now.getDay();
  const monday5AM = new Date(now);
  monday5AM.setHours(5, 0, 0, 0);

  // 開始日を計算
  let startDate = new Date(now);
  if ((currentDay === 0 && now >= monday5AM) || (currentDay === 1 && now < monday5AM)) {
    // 日曜日の5:00以降、または月曜日の5:00以前に実行された場合、先々週の月曜日を設定
    startDate.setDate(startDate.getDate() - currentDay - 6 - 7);
} else {
    // それ以外の場合は、先週の月曜日を設定
    startDate.setDate(startDate.getDate() - currentDay + 1 - 7);
}
  startDate.setHours(0, 0, 0, 0);

  // 現在の週から先週の月曜日までの週数を計算
  const weeksSincePreviousMonday = Math.floor(
    (now.getTime() - startDate.getTime()) / (7 * 24 * 60 * 60 * 1000)
  );

  if (currentDay !== 1 && now < monday5AM) {
    // 先週の月曜日を最新の週の月曜日として設定
    startDate.setDate(startDate.getDate() - weeksSincePreviousMonday * 7 - 7);
  }

  for (let i = 0; i < 53; i++) {
    const currentDate = new Date(startDate);
    currentDate.setDate(currentDate.getDate() - i * 7);

    // 日付が常に月曜日であることを確認
    currentDate.setDate(
      currentDate.getDate() - ((currentDate.getDay() + 6) % 7)
    );
    const formattedLabel = `${currentDate.getFullYear()}/${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${currentDate.getDate().toString().padStart(2, "0")}`;

    options.push(formattedLabel + "~");
  }

  return options;
};
// 過去１年間の日付を月ごとに成形
export const getMonthOptions = (): string[] => {
  const options: string[] = [];
  let currentDate = new Date();
  const firstDayOfMonth5AM = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1,
    5,
    0,
    0
  );

  // 現在が月初のAM5:00以前かどうかを確認
  if (currentDate < firstDayOfMonth5AM) {
    // 日付を月初に設定
    currentDate.setDate(1);
    // 2か月前に設定
    currentDate.setMonth(currentDate.getMonth() - 2);
  } else {
    // 日付を月初に設定
    currentDate.setDate(1);
    // 先月に設定
    currentDate.setMonth(currentDate.getMonth() - 1);
  }

  for (let i = 0; i < 13; i++) {
    // 13ヶ月分のループ
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // 月は0から始まるため+1する
    const formattedLabel = `${year}/${month.toString().padStart(2, "0")}`;
    options.push(formattedLabel);

    // 1ヶ月前に移動
    currentDate.setMonth(currentDate.getMonth() - 1);
  }

  return options;
};

// 次世代システムのフォントを反映
export const fontTheme = () => createTheme({
  typography: {
    fontFamily: ['Noto Sans JP', 'Roboto'].join(","),
  },
});

// 指定文字で改行
export const insertLineBreaks = (str: string, maxLength: number) => {
  if (str?.length <= maxLength) return str;
  const regex = new RegExp(`(.{1,${maxLength}})`, 'g');
  const matches = str?.match(regex);
  return matches ? matches.join('\n') : str;
};